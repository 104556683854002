(function () {
    'use strict';

    angular.module("aerosApp", ["common", "loginApp", "adminApp", "mfgApp", "linkApp"]);

    angular.module('aerosApp')
        .run(customTranslationStrategy)
        .run(['$rootScope', '$state', '$location', 'PageService', 'instructions',
            function ($rootScope, $state, $location, PageService, instructions) {

                (function setCustomerInstructionPages() {
                    PageService.addPages(instructions);
                })();

                window.aflPageView = $rootScope.aflPageView = "";

                $rootScope.userSettings = {};
                $rootScope.reject = function (projectId) {
                    if (typeof projectId === "string") {
                        $location.url('/projects/' + projectId + '/fibergroups/');
                    } else {
                        $location.url('/projects');
                    }
                };

                $rootScope.$on('$stateChangeStart', function (evt, to, params) {
                    if (to.redirect) {
                        evt.preventDefault();
                        /*  We are redirecting to a new route. We do not want to modify to.redirect.params so we create a
                         new object to provide to $state.go. Any fields in to.redirect.params will take precendence
                         over matching fields in params...as we are redirecting to a new target, the values
                         associated with that new target take precedence over the ones in original target.
                        */
                        $state.go(to.redirect.url, _.defaults({}, to.redirect.params, params), {location: 'replace'})
                    }
                });

                $rootScope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams){
                    PageService.clearAflPageView();
                    PageService.setStateAflPageView();
                });
            }]);

    angular.element(document).ready(function () {
        angular.bootstrap(document, ["aerosApp"]);
    });
}());
